/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRequestBoard = /* GraphQL */ `
  query GetRequestBoard($id: ID!) {
    getRequestBoard(id: $id) {
      id
      type
      title
      description
      totalLikeCount
      totalRequestCount
      url
      createdAt
      owner
      updatedAt
      list {
        items {
          id
          boardId
          title
          like
          createdAt
          owner
          updatedAt
          user {
            id
            preferred_username
            profile
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listRequestBoards = /* GraphQL */ `
  query ListRequestBoards(
    $filter: ModelRequestBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        totalLikeCount
        totalRequestCount
        url
        createdAt
        owner
        updatedAt
        list {
          nextToken
        }
        user {
          id
          preferred_username
          profile
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const byCreatedAt = /* GraphQL */ `
  query ByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        description
        totalLikeCount
        totalRequestCount
        url
        createdAt
        owner
        updatedAt
        list {
          nextToken
        }
        user {
          id
          preferred_username
          profile
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        boardId
        title
        like
        createdAt
        owner
        updatedAt
        user {
          id
          preferred_username
          profile
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      preferred_username
      profile
      createdAt
      updatedAt
      owner
    }
  }
`;
