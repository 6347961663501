<template>
  <v-main>
    <!-- エラー表示 -->
    <v-container v-if="isErrorPage">
      <v-row>
        <v-col>
          <v-alert type="info">エラーです。再度お試しください。</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <!-- ヒーローイメージ -->
    <div class="stripe">
      <v-divider></v-divider>
      <v-container v-if="!$store.getters.isSignIn">
        <v-row class="hero my-5">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" align-self="center">
            <v-sheet color="white" class="text-center py-1 mx-4">
              <h2 class="hero-title mx-5 py-5">
                {{ description }}
              </h2>
              <div class="hero-p mx-5 pt-4 pb-5">
                下のボタンから簡単に投票所を作ることが出来ます！
              </div>
            </v-sheet>
          </v-col>
          <v-col cols="0" sm="12" md="6" lg="6" xl="6"
            ><v-sheet rounded="rounded-0"><v-img :src="heroImg" /></v-sheet
          ></v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>
    </div>
    <!-- 特定Board -->
    <div>
      <v-container v-if="isViewDeveloperBoard">
        <v-row>
          <v-col>
            <h4 class="ml-0 my-4">開発者より</h4>
            <v-card elevation="1">
              <v-list class="pt-0 pb-0">
                <v-list-item :to="'/' + developmentBoard.id">
                  <v-list-item-title>{{
                    developmentBoard.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <h4 class="ml-0 my-4">今までに作られた投票所</h4>
            <!-- リスト -->
            <v-card>
              <request-board-list :list.sync="list"></request-board-list>
            </v-card>
          </v-col>
        </v-row>
        <v-sheet class="mb-4"> </v-sheet>
      </v-container>
    </div>

    <!-- 画面下部ボタン -->
    <main-button @openDialog="openDialog"
      >新しい投票所を作る。(サインインが必要)</main-button
    >

    <!-- 書き込みフォーム -->
    <v-dialog v-model="dialog" max-width="500px" @click:outside="dialogHide">
      <request-board-form
        ref="request-board-form"
        :list.sync="list"
        :dialog.sync="dialog"
      ></request-board-form>
    </v-dialog>

    <!-- ログインしてねinfo -->
    <v-dialog v-model="errorDialog" max-width="500px">
      <v-alert class="mb-0" type="info" elevation="0">
        新しい投票所を作るには、サインインしてください。
      </v-alert>
    </v-dialog>
  </v-main>
</template>

<script>
import MainButton from "@/components/MainButton.vue";
import RequestBoardForm from "@/components/Form/RequestBoardForm.vue";
import RequestBoardList from "@/components/List/RequestBoardList.vue";
import { getRequestBoard } from "@/graphql/queries";
import { API } from "@aws-amplify/api";
import Util from "@/Util/Util";

export default {
  components: {
    MainButton,
    RequestBoardForm,
    RequestBoardList,
  },
  data() {
    return {
      // エラーページ
      isErrorPage: false,
      isViewDeveloperBoard: true,
      // 投稿フォーム
      dialog: false,
      // ログインしていない時の注意
      errorDialog: false,

      // 板のリスト
      list: [],

      heroImg: require("@/assets/title_anime.gif"),

      // 開発者より
      developmentBoard: {
        id: "",
        title: "",
        description: "",
        totalLikeCount: 0,
        totalRequestCount: 0,
      },

      description:
        "生放送やWeb勉強会のお供にリアルタイムな投票所を作成できます！",
    };
  },

  methods: {
    // ダイアログを閉じたらフォームをリセット
    dialogHide() {
      this.$refs["request-board-form"].clearForm();
    },

    // 投稿フォームを開く。サインしていないと投稿できない。
    openDialog() {
      if (this.$store.getters.isSignIn) {
        this.dialog = true;
      } else {
        this.errorDialog = true;
      }
    },

    // 特定の投票所を取得
    async getDevelopmentBoard() {
      let req = await API.graphql({
        query: getRequestBoard,
        variables: { id: "a96f8f60-bcc3-414d-ba8a-9707c12e70bb" },
      });
      if (req.data.getRequestBoard != null) {
        this.developmentBoard = req.data.getRequestBoard;
        console.log("開発者よりBoardを取得", this.developmentBoard);
      } else {
        this.isViewDeveloperBoard = false;
      }
    },
  },

  async created() {
    try {
      Util.setTitle(process.env.VUE_APP_TITLE, this.description);
      await this.getDevelopmentBoard();
    } catch (e) {
      console.log(e);
      this.isViewDeveloperBoard = false;
    }
  },
};
</script>

<style lang="scss">
.stripe {
  background-size: auto auto;
  background-color: rgba(255, 255, 255, 1);
  background-image: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 15px,
    rgba(255, 240, 240, 1) 15px,
    rgba(255, 240, 240, 1) 37px
  );
}
</style>
