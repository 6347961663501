import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let state = {
  user: null,
  requestBoardTitle: null,
};

export const mutations = {
  // user情報をセット
  setUser(state, user) {
    state.user = user;
  },
  // ナビゲーションバー用にリクエストボードのタイトルをセット
  setRequestBoardTitle(state, title) {
    state.requestBoardTitle = title;
  },
  // リクエストボードが見つからないとき用
  setRequestBoardTitleNotFound(state) {
    state.requestBoardTitle = null;
  },
};

export const getters = {
  // サインインしているか
  isSignIn: (state) => {
    return state.user != null;
  },
  // オーナーか
  isOwner: (state) => (id) => {
    return state.user.username === id;
  },
  // リクエストボードが見つからないか
  isRequestBoardNotFound(state) {
    return state.requestBoardTitle == null;
  },
  // 変更可能な方のユーザー名を返す
  getPUserName(state) {
    return state.user.signInUserSession.idToken.payload.preferred_username;
  },
};

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  getters: getters,
  actions: {},
  modules: {},
});
