export const messages = {
  "ja-JP": {
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      "パスワードは8文字以上にしてください",
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
      "パスワードは8文字以上にしてください",
    "Account recovery requires verified contact information":
      "コンタクトの情報を検証する為に、アカウントの回復が必要です",
    "An account with the given email already exists.":
      "このメールアドレスはすでに登録されています",
    "Custom auth lambda trigger is not configured for the user pool.":
      "パスワードが入力されていません",
    "Cannot reset password for the user as there is no registered/verified email or phone_number":
      "メールアドレスや電話番号が登録されていないため、ユーザーのパスワードをリセットできません。",
    "Incorrect username or password.": "ユーザー名またはパスワードが異なります",
    "Invalid password format": "パスワードのフォーマットが無効です",
    "Invalid verification code provided, please try again.":
      "無効な確認コードです。再度ご確認ください",
    "Password cannot be empty": "パスワードを入力してください",
    "Password did not conform with policy: Password not long enough":
      "パスワードは8文字以上にしてください",
    "User does not exist": "ユーザーが存在しません",
    "User does not exist.": "ユーザーが存在しません",
    "Username cannot be empty": "ユーザー名を入力してください",
  },
};
