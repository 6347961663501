<template>
  <v-app>
    <app-navigation></app-navigation>
    <v-divider></v-divider>
    <router-view />
  </v-app>
</template>
<script>
import AppNavigation from "@/components/AppNavigation.vue";

export default {
  components: { AppNavigation },
};
</script>


