/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRequestPublic = /* GraphQL */ `
  subscription OnCreateRequestPublic($boardId: ID!) {
    onCreateRequestPublic(boardId: $boardId) {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onUpdateRequestPublic = /* GraphQL */ `
  subscription OnUpdateRequestPublic($boardId: ID!) {
    onUpdateRequestPublic(boardId: $boardId) {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onDeleteRequestPublic = /* GraphQL */ `
  subscription OnDeleteRequestPublic($boardId: ID!) {
    onDeleteRequestPublic(boardId: $boardId) {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onCreateRequestBoard = /* GraphQL */ `
  subscription OnCreateRequestBoard {
    onCreateRequestBoard {
      id
      type
      title
      description
      totalLikeCount
      totalRequestCount
      url
      createdAt
      owner
      updatedAt
      list {
        items {
          id
          boardId
          title
          like
          createdAt
          owner
          updatedAt
          user {
            id
            preferred_username
            profile
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onUpdateRequestBoard = /* GraphQL */ `
  subscription OnUpdateRequestBoard {
    onUpdateRequestBoard {
      id
      type
      title
      description
      totalLikeCount
      totalRequestCount
      url
      createdAt
      owner
      updatedAt
      list {
        items {
          id
          boardId
          title
          like
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onDeleteRequestBoard = /* GraphQL */ `
  subscription OnDeleteRequestBoard {
    onDeleteRequestBoard {
      id
      type
      title
      description
      totalLikeCount
      totalRequestCount
      url
      createdAt
      owner
      updatedAt
      list {
        items {
          id
          boardId
          title
          like
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onCreateRequest = /* GraphQL */ `
  subscription OnCreateRequest {
    onCreateRequest {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onUpdateRequest = /* GraphQL */ `
  subscription OnUpdateRequest {
    onUpdateRequest {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onDeleteRequest = /* GraphQL */ `
  subscription OnDeleteRequest {
    onDeleteRequest {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      preferred_username
      profile
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      preferred_username
      profile
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      preferred_username
      profile
      createdAt
      updatedAt
      owner
    }
  }
`;
