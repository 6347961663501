import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import "@aws-amplify/ui-vue";
import Amplify from "@aws-amplify/core";
import aws_exports from "./aws-exports";
import vuetify from "./plugins/vuetify";

Amplify.configure(aws_exports);

import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
const ja = require("@aws-amplify-jp/vocabulary-ja");

import { messages } from "./formMessage";
I18n.putVocabularies(messages);

I18n.putVocabulariesForLanguage("ja-JP", ja(Translations));
I18n.setLanguage('ja-JP');

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
