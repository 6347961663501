import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { variations: false },
    themes: {
      light: {
        primary: colors.pink.lighten2,
      },
    },
  },
});
