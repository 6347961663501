<template>
  <v-main>
    <v-divider></v-divider>
    <!-- 投票所 -->
    <v-container class="mb-5">
      <v-alert type="info" v-if="isErrorPage"
        >このアドレスの投票所は存在しないようです。メインページよりお探しください。</v-alert
      >
      <template v-else>
        <!-- 投票所情報 -->
        <v-sheet class="pt-5 pb-2">
          <!-- タイトル -->
          <div class="text-center" style="position: relative">
            <!-- 書き換えボタン -->
            <v-btn
              fab
              icon
              absolute
              top
              right
              class="mt-5"
              @click="onClickRewrite"
              v-if="
                $store.getters.isSignIn &&
                $store.getters.isOwner(requestBoard.owner)
              "
              ><v-icon>mdi-pen</v-icon></v-btn
            >
            <h2 class="requetBoardTitle my-2">{{ requestBoard.title }}</h2>
          </div>
          <!-- 会場URL -->
          <div
            class="d-flex justify-center pt-4"
            v-if="requestBoard.url != null"
          >
            <span> 会場URL : </span>
            <a v-if="requestBoard.url != null" :href="requestBoard.url">
              {{ requestBoard.url }}
            </a>
          </div>
          <!-- 内容類 -->
          <div
            class="px-4 pb-4 pt-3"
            v-if="requestBoard.description != null || requestBoard.url != null"
          >
            <div class="pt-5" v-if="requestBoard.description != null">
              {{ requestBoard.description }}
            </div>
          </div>
        </v-sheet>

        <v-divider
          v-if="requestBoard.description != null || requestBoard.url != null"
        ></v-divider>
        <!-- 項目リスト ランキング -->

        <request-list
          ref="requestList"
          :items.sync="requestBoard.list.items"
          :request-board-id="requestBoard.id"
          :totallikecount.sync="requestBoard.totalLikeCount"
          :totalrequestcount.sync="requestBoard.totalRequestCount"
          :login-info-dialog.sync="loginInfoDialog"
          :is-view-info="isViewInfo"
        ></request-list>
      </template>
      <v-sheet class="mb-4"> </v-sheet>
    </v-container>

    <!-- 画面下部 メインボタン -->
    <main-button v-if="!isErrorPage" @openDialog="onClickOpenFormButton"
      >新しい項目を書く</main-button
    >

    <!-- 書き込みフォーム -->
    <v-dialog v-model="dialog" max-width="500px" @click:outside="dialogHide">
      <create-request-form
        ref="request_form"
        :dialog.sync="dialog"
        :request-board.sync="requestBoard"
      ></create-request-form>
    </v-dialog>

    <!-- 編集フォーム -->
    <v-dialog
      v-model="rewriteDialog"
      max-width="500px"
      @click:outside="rewriteDialogHide"
    >
      <rewrite-request-board-form
        ref="rewrite_form"
        :dialog.sync="rewriteDialog"
        :request-board.sync="requestBoard"
      ></rewrite-request-board-form>
    </v-dialog>

    <!-- ログインしてくださいinfo -->
    <v-dialog
      v-model="loginInfoDialog"
      max-width="500px"
      @click:outside="loginInfoDialog = false"
    >
      <v-alert class="mb-0" type="info" elevation="0" @click="onClickLoginInfo">
        新しい書き込みや投票をおこなうには、サインインする必要があります。
        ぜひサインインしてください。
      </v-alert>
    </v-dialog>
  </v-main>
</template>

<script>
import { getRequestBoard } from "@/graphql/queries";
import { API } from "aws-amplify";
import MainButton from "@/components/MainButton.vue";
import CreateRequestForm from "@/components/Form/CreateRequestForm.vue";
import RewriteRequestBoardForm from "@/components/Form/RewriteRequestBoardForm.vue";
import RequestList from "@/components/List/RequestList.vue";
import Util from "@/Util/Util";

export default {
  components: {
    MainButton,
    CreateRequestForm,
    RequestList,
    RewriteRequestBoardForm,
  },

  data() {
    return {
      // エラー時
      isErrorPage: false,
      // 初回ロード終わったかどうか
      isGetData: false,

      // ログインしてねダイアログ
      loginInfoDialog: false,

      // 投稿フォーム
      dialog: false,
      rewriteDialog: false,

      // 掲示板情報
      requestBoard: {
        id: "",
        title: "",
        description: null,
        url: null,
        totalLikeCount: 0,
        totalRequestCount: 0,
        createdAt: "",
        updatedAt: "",
        list: { items: [] },
      },
    };
  },
  methods: {
    // testdata 作成
    testData() {
      return {
        id: "",
        type: "b",
        title: "hello",
        description: "aaaaaaa aaaa ",
        totalLikeCount: 0,
        totalRequestCount: 0,
        list: {
          items: [
            { id: "aaa", title: "aaaしてほしい", like: 0 },
            { id: "bbb", title: "bbbしてほしい", like: 10 },

            {
              id: "ccc",
              title: "yaaaaaaaaaaa aaaaaaaaaa aaaaaaaaaaaaaaa aaaaaaaa",
              like: 10000,
            },
          ],
        },
      };
    },

    dialogHide() {
      this.$refs["request_form"].clearForm();
    },

    rewriteDialogHide() {
      this.$refs["rewrite_form"].clearForm();
    },

    // フォームダイアログを出す
    onClickOpenFormButton() {
      if (this.$store.getters.isSignIn) {
        this.dialog = true;
      } else {
        this.loginInfoDialog = true;
      }
    },

    // 編集ボタン
    onClickRewrite() {
      this.rewriteDialog = true;
      console.log("hello");
    },

    // 板の情報を取得
    async getData() {
      try {
        const res = await API.graphql({
          query: getRequestBoard,
          variables: { id: this.$route.params.requestBoardId },
        });
        // 返ってこなければエラーページ
        if (res.data.getRequestBoard == null) {
          this.isErrorPage = true;
          this.$store.commit("setRequestBoardTitleNotFound");
          return;
        }
        // 返ってきたら設定
        console.log("板情報を取得 : ", res.data);
        this.requestBoard = res.data.getRequestBoard;
        this.$store.commit("setRequestBoardTitle", this.requestBoard.title);
        this.isGetData = true;
      } catch (e) {
        console.error(e);
        this.isErrorPage = true;
      }
    },

    // ログインしてねinfoをクリックされた場合とじて飛ばす
    onClickLoginInfo() {
      this.loginInfoDialog = false;
      this.$router.push("/signin");
    },
  },

  computed: {
    isListEmpty() {
      return !this.requestBoard.list.items.length;
    },

    // 書いてねインフォを表示するか
    isViewInfo() {
      if (!this.isGetData) {
        return false;
      } else if (this.isErrorPage) {
        return false;
      } else if (this.isListEmpty) {
        return true;
      } else {
        return false;
      }
    },
  },

  async created() {
    // get してから subscribe
    await this.getData();
    if (!this.isErrorPage) {
      Util.setTitle(
        this.requestBoard.title + " | " + process.env.VUE_APP_TITLE,
        this.requestBoard.description,
        undefined,
        process.env.VUE_APP_SITEURL + "/" + this.requestBoard.id
      );
      this.$refs["requestList"].subscribe();
    }
  },
};
</script>

<style scoped>
.newItemInfo {
  background-color: #64b5f6;
}
.newItemInfo-content {
  color: white;
}

/* title */
.requetBoardTitle {
  word-break: break-all;
}

.wrap-text {
  word-break: break-all;
  white-space: normal;
}
</style>