<template>
  <v-card>
    <v-card-title>新しい項目</v-card-title>
    <v-card-text>
      <v-form ref="create_request_form" v-model="isValid" lazy-validation>
        <v-textarea
          ref="title"
          solo
          counter
          autofocus
          :rules="rules"
          v-model="formText"
          @keydown="formKeyDown"
        ></v-textarea>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click.stop="onClickSubmit">
        新しい項目を書く
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { API } from "@aws-amplify/api";
import { createRequest, incrementTotalRequestCount } from "@/graphql/mutations";

export default {
  data() {
    return {
      formText: null,

      isValid: true,

      rules: [
        // null と空文字チェック
        (value) => !!value || "項目を入力してください",
        // null ok 200文字以内
        (v) => v == null || v.length <= 200 || "最大200文字まで",
      ],
    };
  },

  props: {
    dialog: {
      type: Boolean,
    },
    requestBoard: {
      type: Object,
    },
  },

  methods: {
    clearForm() {
      this.$refs["create_request_form"].reset();
    },

    // 新しい項目を作成
    async createRequest(newRequest, copy) {
      // まず新しい項目を追加
      const res = await API.graphql({
        query: createRequest,
        variables: { input: newRequest },
      });
      console.log("新しい項目を追加", res.data.createRequest);
      copy.list.items.push(res.data.createRequest);
      this.$emit("update:requestBoard", copy);
      return copy;
    },

    // 総項目数を更新
    async updateTotalCount(copy) {
      // 総数を更新.
      const resBoard = await API.graphql({
        query: incrementTotalRequestCount,
        variables: {
          id: copy.id,
        },
      });

      console.log("項目数更新 : ", resBoard);
      this.$emit(
        "update:requestBoard",
        resBoard.data.incrementTotalRequestCount
      );
    },

    // 新しい項目を追加
    async postNewRequest() {
      // 新しい項目
      let newRequest = {
        boardId: this.requestBoard.id,
        title: this.formText,
        like: 0,
        owner: this.$store.state.user.username,
      };

      let copy = Object.assign({}, this.requestBoard);
      try {
        await this.createRequest(newRequest, copy);
        await this.updateTotalCount(copy);

        // 成功したらフォームをクリア、閉じる
        this.clearForm();
        this.$emit("update:dialog", false);
      } catch (e) {
        console.log(e);
      }
    },

    // 新しい項目を書く
    onClickSubmit() {
      // 未ログインまたはバリデーションエラーだと何も起きない
      if (
        !this.$store.getters.isSignIn ||
        !this.$refs["create_request_form"].validate()
      ) {
        console.log("バリデーションエラー！");
        return;
      }
      this.postNewRequest();
    },

    // ctrl + enter で確定
    formKeyDown(event) {
      // console.log(event);
      if (event.ctrlKey && event.keyCode == 13) {
        this.onClickSubmit();
      }
    },
  },
};
</script>