<template>
  <v-main>
    <v-divider></v-divider>
    <v-container>
      <div class="text-center">
        <h4 class="pa-5">
          サービスを利用する前にユーザー名を登録する必要があります。
        </h4>
      </div>
      <!-- <div class="d-flex justify-center pa-3">
        <v-avatar color="primary" size="100">o</v-avatar>
      </div> -->
      <user-name-setting-form> </user-name-setting-form>
    </v-container>
  </v-main>
</template>

<script>
import UserNameSettingForm from "@/components/Form/UserNameSettingForm.vue";

export default {
  components: {
    UserNameSettingForm,
  },
};
</script>