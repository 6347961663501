<template>
  <v-main>
    <v-row justify="center" align="start">
      <amplify-authenticator id="box" username-alias="email">
        <!-- The rest of your app code -->
        <amplify-sign-up
          slot="sign-up"
          username-alias="email"
          :form-fields.prop="formFields"
        ></amplify-sign-up>
        <amplify-sign-out></amplify-sign-out>
      </amplify-authenticator>
    </v-row>
  </v-main>
</template>

<script>
import { Hub } from "@aws-amplify/core";
export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      formFields: [
        {
          type: "email",
          label: "Email",
          placeholder: "",
          required: true,
        },
        {
          type: "password",
          label: "Password",
          placeholder: "",
          required: true,
        },
      ],
    };
  },

  methods: {
    async listener(data) {
      console.log("auth evnet! :", data.payload.event);
      switch (data.payload.event) {
        // サインアップ
        case "confirmSignUp":
          this.$router.push({ name: "UserNameSetting" });
          break;
        // サインインしたら飛ばす
        case "signIn":
          if (this.$route.query.redirect != null) {
            this.$router.push({ path: this.$route.query.redirect });
          } else {
            this.$router.push({ path: "/" });
          }
          break;
        // サインアウトしたら飛ばす
        case "signOut":
          this.$store.commit("setUser", null);
          if (this.$route.query.redirect != null) {
            this.$router.push({ path: this.$route.query.redirect });
          } else {
            this.$router.push({ path: "/" });
          }
          break;
      }
    },
  },

  created() {
    // ログインフォームを使ったauth状態イベント
    Hub.listen("auth", this.listener);
  },

  destroyed() {
    Hub.remove("auth", this.listener);
  },
};
</script>

<style scoped>
#box {
  --box-shadow: none;
}
</style>