<template>
  <v-app-bar class="border-bottom" app elevate-on-scroll color="white">
    <template v-if="is('MainPage')">
      <!-- test用のid -->
      <v-toolbar-title id="siteName">{{ siteName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <avatar-block></avatar-block>
      <sign-in-out-button></sign-in-out-button>
    </template>

    <template v-else-if="is('SignIn')">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-chevron-left</v-icon></v-btn
      >
      <v-toolbar-title>サインイン</v-toolbar-title>
    </template>

    <template v-else-if="is('RequestBoard')">
      <v-btn icon to="/">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="$store.getters.isRequestBoardNotFound"
        >....</v-toolbar-title
      >
      <v-toolbar-title v-else>{{
        this.$store.state.requestBoardTitle
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <avatar-block></avatar-block>
      <sign-in-out-button />
    </template>

    <template v-else>
      <!-- test用のid -->
      <v-toolbar-title id="siteName">{{ siteName }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </template>
  </v-app-bar>
</template>
<script>
import SignInOutButton from "@/components/SignInOutButton";
import AvatarBlock from "@/components/AvatarBlock";

export default {
  components: { SignInOutButton, AvatarBlock },
  data() {
    return {
      // サイト名
      siteName: process.env.VUE_APP_TITLE,
    };
  },

  methods: {
    is(str) {
      return this.$route.name === str;
    },
  },
};
</script>

<style scoped>
.appBar {
  border-bottom: solid;
}
</style>