import { API } from "aws-amplify";

export default class Util {
  // listをidで検索してあれば更新 vue用
  static updateListItem(list, value) {
    const idx = list.findIndex((item) => item.id === value.id);
    if (idx !== -1) {
      list.splice(idx, 1, value);
    }
  }

  static updatePushListItem(list, value) {
    const idx = list.findIndex((item) => item.id === value.id);
    if (idx !== -1) {
      list.splice(idx, 1, value);
    } else {
      list.push(value);
    }
  }

  // graphqlのパターン
  static async graphql(before, query, value, consoleStr, after, error) {
    before();
    try {
      const res = await API.graphql({
        query: query,
        variables: value,
      });
      console.log(consoleStr, res);
      after(res.data[query]);
    } catch (e) {
      error(e);
    }
  }

  // titleとか設定する
  static setTitle(title, description, img, url) {
    let title_ = title;
    document.title = title_;
    document
      .querySelector("meta[property='og:title']")
      .setAttribute("content", title_);

    if (description != null) {
      document
        .querySelector("meta[name='description']")
        .setAttribute("content", description);
      document
        .querySelector("meta[property='og:description']")
        .setAttribute("content", description);
    }

    if (img != null) {
      document
        .querySelector("meta[property='og:image']")
        .setAttribute("content", img);
    }

    if (url != null) {
      document
        .querySelector("meta[property='og:url']")
        .setAttribute("content", url);
    }
  }

  //stackoverflowからコピペ
  static isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
}
