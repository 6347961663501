/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const incrementLikeCount = /* GraphQL */ `
  mutation IncrementLikeCount($id: ID!) {
    incrementLikeCount(id: $id) {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const incrementTotalLikeCount = /* GraphQL */ `
  mutation IncrementTotalLikeCount($id: ID!) {
    incrementTotalLikeCount(id: $id) {
      id
      type
      title
      description
      totalLikeCount
      totalRequestCount
      url
      createdAt
      owner
      updatedAt
      list {
        items {
          id
          boardId
          title
          like
          createdAt
          owner
          updatedAt
          user {
            id
            preferred_username
            profile
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const incrementTotalRequestCount = /* GraphQL */ `
  mutation IncrementTotalRequestCount($id: ID!) {
    incrementTotalRequestCount(id: $id) {
      id
      type
      title
      description
      totalLikeCount
      totalRequestCount
      url
      createdAt
      owner
      updatedAt
      list {
        items {
          id
          boardId
          title
          like
          createdAt
          owner
          updatedAt
          user {
            id
            preferred_username
            profile
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createRequestBoard = /* GraphQL */ `
  mutation CreateRequestBoard(
    $input: CreateRequestBoardInput!
    $condition: ModelRequestBoardConditionInput
  ) {
    createRequestBoard(input: $input, condition: $condition) {
      id
      type
      title
      description
      totalLikeCount
      totalRequestCount
      url
      createdAt
      owner
      updatedAt
      list {
        items {
          id
          boardId
          title
          like
          createdAt
          owner
          updatedAt
          user {
            id
            preferred_username
            profile
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateRequestBoard = /* GraphQL */ `
  mutation UpdateRequestBoard(
    $input: UpdateRequestBoardInput!
    $condition: ModelRequestBoardConditionInput
  ) {
    updateRequestBoard(input: $input, condition: $condition) {
      id
      type
      title
      description
      totalLikeCount
      totalRequestCount
      url
      createdAt
      owner
      updatedAt
      list {
        items {
          id
          boardId
          title
          like
          createdAt
          owner
          updatedAt
          user {
            id
            preferred_username
            profile
            createdAt
            updatedAt
            owner
          }
        }
        nextToken
      }
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteRequestBoard = /* GraphQL */ `
  mutation DeleteRequestBoard(
    $input: DeleteRequestBoardInput!
    $condition: ModelRequestBoardConditionInput
  ) {
    deleteRequestBoard(input: $input, condition: $condition) {
      id
      type
      title
      description
      totalLikeCount
      totalRequestCount
      url
      createdAt
      owner
      updatedAt
      list {
        items {
          id
          boardId
          title
          like
          createdAt
          owner
          updatedAt
        }
        nextToken
      }
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      boardId
      title
      like
      createdAt
      owner
      updatedAt
      user {
        id
        preferred_username
        profile
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      preferred_username
      profile
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      preferred_username
      profile
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      preferred_username
      profile
      createdAt
      updatedAt
      owner
    }
  }
`;
