import Vue from "vue";
import VueRouter from "vue-router";
import RequestBoard from "@/views/RequestBoard.vue";
import MainPage from "@/views/MainPage.vue";

Vue.use(VueRouter);

import SignIn from "@/views/SignIn.vue";
import UserNameSetting from "@/views/UserNameSetting.vue";

import Auth from "@aws-amplify/auth";

import UserDataStore from "@/store/index";
import Amplify from "aws-amplify";
// 現在のログイン情報を取得
function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        console.log("サインイン状態を取得。ログイン中 :", data);
        // ユーザー情報を設定
        UserDataStore.commit("setUser", data);
        // Amplifyの認証方法の設定
        Amplify.configure({
          aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
        });
        return data;
      }
    })
    .catch(() => {
      console.log("サインイン状態を取得。ログインしていない");
      // 認証をiamに設定
      Amplify.configure({
        aws_appsync_authenticationType: "AWS_IAM",
      });
      UserDataStore.commit("setUser", null);
      return null;
    });
}

const routes = [
  {
    path: "/",
    name: "MainPage",
    component: MainPage,
    meta: { requiresAuth: false },
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
    meta: { requiresAuth: false },
  },

  {
    path: "/signin/userNameSetting",
    name: "UserNameSetting",
    component: UserNameSetting,
    meta: { requiresAuth: true },
  },

  {
    path: "/:requestBoardId",
    name: "RequestBoard",
    component: RequestBoard,
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// ユーザ名を設定済みか、
// 設定済み true
function isUpdatedUserName(user) {
  return user.signInUserSession.idToken.payload.preferred_username != null;
}

// ページへのアクセスの前
router.beforeResolve(async (to, from, next) => {
  const user = await getUser();

  console.log("beforeResolve! :", to, from, user);

  // userName設定ページ直接
  if (to.name === "UserNameSetting") {
    // 名前を設定していたらindex
    if (isUpdatedUserName(user)) {
      return next("/");
    } else {
      return next();
    }
  }

  // ログインしている かつ ユーザー名を設定していない
  if (user != null && !isUpdatedUserName(user)) {
    return next({
      name: "UserNameSetting",
    });
  }

  // それ以外
  return next();
});

export default router;
