<template>
  <v-footer fixed color="transparent">
    <v-row justify="center">
      <v-btn
        class="create_polling_button"
        color="primary"
        dark
        @click.stop="openDialog"
        ><slot></slot
      ></v-btn>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  methods: {
    openDialog() {
      this.$emit("openDialog");
    },
  },
};
</script>