/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:411071bf-ddf1-4c1d-89dc-facf8247a06b",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_VAloIHUGj",
    "aws_user_pools_web_client_id": "1q1aiu3hksbvlq25nu96jrjcqf",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ut3kzex3gjdbfby2yzpjjw6dha.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
