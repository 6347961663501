<template>
  <!-- 項目のリスト -->
  <v-list flat class="mt-3">
    <v-subheader class="font-weight-bold">ランキング</v-subheader>
    <!-- 新しく書き込もうinfo -->
    <v-list-item v-if="isViewInfo" class="newItemInfo mt-2">
      <v-list-item-content class="newItemInfo-content" v-if="isViewInfo">
        まだ項目が書き込まれていません。新しく書き込もう！
      </v-list-item-content>
    </v-list-item>
    <!-- リストitem -->
    <transition-group class="pl-0 pl-sm-0 pl-md-3" name="flip-list" tag="ul">
      <template v-for="(request, idx) in ranking">
        <v-divider :key="idx" v-if="idx >= 1"></v-divider>
        <v-list-item tag="li" :key="request.id" class="px-1 px-sm-4">
          <v-list-item-icon class="mr-3 mr-sm-8">
            <!-- 1,2,3はアイコンに変える -->
            <v-icon v-if="idx < 3" :color="oweTwoThreeColor(idx)">{{
              toIconText(idx)
            }}</v-icon>
            <span v-else class="ml-2 text--disabled">{{ idx + 1 }}</span>
          </v-list-item-icon>
          <!-- 項目 -->
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold d-flex">
              <!-- 内容 -->
              <span class="align-self-center wrap-text">{{
                request.title
              }}</span>
              <!-- 名前 -->

              <small
                class="font-italic text--disabled nameSetting ml-2 align-self-center"
              >
                {{ request.user.preferred_username }}</small
              >
            </v-list-item-title>
          </v-list-item-content>
          <!-- いいね ボタン -->
          <div class="d-flex align-items-center align-self-center">
            <span
              style="align-items: center; align-self: center; display: flex"
            >
              {{ request.like }}
            </span>
            <!-- test用id -->
            <v-btn
              id="voteButton"
              color="primary"
              text
              icon
              @click="onPressLike(request)"
            >
              <v-icon>mdi-arrow-up-bold-outline</v-icon>
            </v-btn>
          </div>
        </v-list-item>
      </template>
    </transition-group>
  </v-list>
</template>
<script>
import { API } from "aws-amplify";
import {
  incrementLikeCount,
  incrementTotalLikeCount,
} from "@/graphql/mutations";
import {
  onUpdateRequestPublic,
  onCreateRequestPublic,
} from "@/graphql/subscriptions";
import Util from "@/Util/Util";

export default {
  data() {
    return {
      // サブスク
      updateSubscription: null,
      createSubscription: null,
    };
  },

  props: {
    requestBoardId: {
      type: String,
      required: true,
    },
    totallikecount: {
      type: Number,
      required: true,
    },
    totalrequestcount: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loginInfoDialog: {
      type: Boolean,
      required: true,
    },
    isViewInfo: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    // 1～3位はアイコンを変える。
    toIconText(num) {
      if (0 <= num && num <= 2) {
        return "mdi-numeric-" + (num + 1) + "-box-outline";
      } else {
        return "mdi-numeric-" + (num + 1);
      }
    },

    // 1,2,3位は色を変える
    oweTwoThreeColor(idx) {
      if (idx === 0) return "#ffd257";
      if (idx === 1) return "#c9c9c9";
      if (idx === 2) return "#d19f41";
    },

    // Likeに１追加
    async incrementLike(request) {
      // RequestのLike数をまず更新
      const res1 = await API.graphql({
        query: incrementLikeCount,
        variables: {
          id: request.id,
        },
      });
      console.log("Like数更新", res1, request);
      // listを書き換える
      request.like = res1.data.incrementLikeCount.like;
      return request;
    },

    // like総数を更新
    async incrementTotalLikeCount() {
      // RequestBoard の総数を更新
      const resBoard = await API.graphql({
        query: incrementTotalLikeCount,
        variables: {
          id: this.requestBoardId,
        },
      });
      console.log("ボードのLike総数を更新", resBoard);
      this.$emit(
        "update:totallikecount",
        resBoard.data.incrementTotalLikeCount.totalLikeCount
      );
    },

    // Likeボタンを押したとき
    async onPressLike(request) {
      // ログインしていない場合、ログイン誘導インフォを表示
      if (!this.$store.getters.isSignIn) {
        this.$emit("update:loginInfoDialog", true);
      } else {
        try {
          await this.incrementLike(request);
          await this.incrementTotalLikeCount();
        } catch (e) {
          console.error(e);
        }
      }
    },

    // 項目の情報の更新を検知
    // ログインしていない人もしている人もこれでサブスク
    subscribeUpdatePublic() {
      this.updateSubscription = API.graphql({
        query: onUpdateRequestPublic,
        variables: { boardId: this.requestBoardId },
      }).subscribe({
        next: (eventData) => {
          console.log("サブスク 取得 ", eventData);
          if (eventData.value.data.onUpdateRequestPublic == null) {
            console.log("サブスク 空");
            return;
          }
          let request = eventData.value.data.onUpdateRequestPublic;
          Util.updateListItem(this.items, request);
        },
        error: (e) => {
          console.error("サブスクエラー", e);
        },
      });
      console.log("サブスク開始", this.requestBoardId);
    },

    // 項目の作成を検知
    subscribeCreatePublic() {
      this.createSubscription = API.graphql({
        query: onCreateRequestPublic,
        variables: { boardId: this.requestBoardId },
      }).subscribe({
        next: (eventData) => {
          console.log("サブスク 取得 ", eventData);
          if (eventData.value.data.onCreateRequestPublic == null) {
            return;
          }
          let request = eventData.value.data.onCreateRequestPublic;
          let copy = this.items.slice();
          Util.updatePushListItem(copy, request);
          this.$emit("update:items", copy);
        },
        error: (e) => {
          console.error("サブスクエラー", e);
        },
      });
      console.log("サブスク開始", this.requestBoardId);
    },

    //サブスクライブ
    subscribe() {
      // updateをサブスクライブ
      this.subscribeUpdatePublic();
      this.subscribeCreatePublic();
    },

    // サブスク解除
    unSubscribe() {
      console.log("サブスク解除");
      if (this.updateSubscription != null)
        this.updateSubscription.unsubscribe();
      if (this.createSubscription != null)
        this.createSubscription.unsubscribe();
    },
  },

  computed: {
    // like順にソートする
    ranking() {
      return this.items.slice().sort((a, b) => b.like - a.like);
    },
  },

  beforeDestroy() {
    this.unSubscribe();
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.nameSetting {
  max-width: 200px;
  min-width: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 2px;
}

.wrap-text {
  word-break: break-all;
  white-space: normal;
}
</style>