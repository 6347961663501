













































import Vue from "vue";
import { API } from "aws-amplify";
import { createRequestBoard } from "@/graphql/mutations";
import Util from "@/Util/Util";

export default Vue.extend({
  data() {
    return {
      // 投稿エラーフラグ
      isFormError: false,
      valid: true,
      // form
      form: { title: "", description: null, url: null },
      // バリデーションルール
      titleFormRule: [
        (value: string | null) => !!value || "作成するにはタイトルが必要です",
        (value: string | null) =>
          value == null || !(value.length > 100) || "100文字以内にしてください",
      ],
      descriptionFormRule: [
        (value: string | null) =>
          value == null ||
          !(value.length > 1000) ||
          "1000文字以内にしてください",
      ],

      urlRule: [
        (v: string | null) =>
          v == null ||
          !v ||
          Util.isValidHttpUrl(v) ||
          "url形式の文字列のみ有効",
      ],
    };
  },
  props: {
    list: {
      type: Array,
    },
    dialog: {
      type: Boolean,
    },
  },
  methods: {
    // フォームをクリアする
    clearForm() {
      (this.$refs.form as any).reset();
      this.isFormError = false;
    },

    // 投稿
    async onClickSubmit() {
      await this.createNewBoard();
    },

    async createRequestBoard() {
      // 作成
      const res: any = await API.graphql({
        query: createRequestBoard,
        variables: {
          input: {
            type: "b",
            title: this.form.title,
            description: !this.form.description ? null : this.form.description,
            url: !this.form.url ? null : this.form.url,
            totalLikeCount: 0,
            totalRequestCount: 0,
            owner: this.$store.state.user.username,
          },
        },
      });
      console.log("create new board! ", res);
      let copyList = this.list.slice();
      copyList.unshift(res.data.createRequestBoard);
      this.$emit("update:list", copyList);
    },

    // 新しい投票所を作成
    async createNewBoard() {
      // バリデーション起動とチェック
      if (!(this.$refs.form as any).validate()) {
        console.log("バリデーションエラー！");
        return;
      }
      try {
        await this.createRequestBoard();
        this.clearForm();
        this.$emit("update:dialog", false);
      } catch (e) {
        console.log(e);
        this.isFormError = true;
      }
    },
  },
});
