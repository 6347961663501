<template>
  <!-- 投票所のリスト -->
  <v-list two-line class="pt-0 pb-0" elevation="1">
    <template v-for="(board, idx) in list">
      <v-divider :key="idx" v-if="idx >= 1"></v-divider>
      <v-list-item :key="board.id" :to="'/' + board.id">
        <v-list-item-content>
          <v-list-item-title class="d-flex">
            <span class="font-weight-bold titleSetting">
              {{ board.title }}
            </span>
            <v-spacer></v-spacer>

            <small class="font-italic text--disabled nameSetting"
              >by {{ board.user.preferred_username }}</small
            >
          </v-list-item-title>
          <!-- 名前 投票数 投稿時間など -->
          <v-list-item-subtitle class="d-flex">
            <span class="ml-1 text--disabled">
              {{ formatTime(board.createdAt) }}
            </span>
            <v-spacer></v-spacer>
            <span class="ml-2">
              <span class="nameSetting">{{
                formatNum(board.totalRequestCount)
              }}</span>
              <v-icon small>mdi-comment-outline</v-icon>
            </span>
            <span class="ml-2">
              <span class="nameSetting">{{
                formatNum(board.totalLikeCount)
              }}</span>
              <v-icon small>mdi-arrow-up-bold-outline</v-icon>
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<style scoped>
.nameSetting {
  max-width: 200px;
  min-width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 2px;
}

.titleSetting {
  min-width: 168px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<script>
import { API } from "aws-amplify";
import { byCreatedAt } from "@/graphql/queries";

import {
  onUpdateRequestBoardPublic,
  onCreateRequestBoardPublic,
} from "@/graphql/subscriptions";

import Util from "@/Util/Util";

// dayjs インストール
import dayjs from "dayjs";
import "dayjs/locale/ja";
var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
dayjs.locale("ja");

export default {
  data() {
    return {
      // サブスクリプション機能 今は使わない
      subscriptionCreate: null,
      subscriptionUpdate: null,
    };
  },

  props: {
    list: {
      type: Array,
    },
  },

  methods: {
    // デザイン確認用テストデータ
    getTestData() {
      let boardTestData = {
        createdAt: "2020-12-09T11:34:03.069Z",
        description: null,
        id: "aaaaa",
        list: { item: [] },
        owner: "94efb462-012c-4006-b90a-d88dd56301d4",
        title:
          "aaaaa aaaaaaaaaaaaaaa aaaaaaaaaaaaaaaaaaaaaaaaaa aaaaaaaaaaaaaaaaa aaaaaaaaaaaaaaaaa aaaaaaaaaaaaaaaaaaaaa aaaaaaaaaaaaa",
        totalLikeCount: 99999999,
        totalRequestCount: 9999999999,
        type: "b",
        updatedAt: "2020-12-09T11:34:03.069Z",
        url: null,
        user: {
          preferred_username: "aaaaaaaaa aaaaaa aaaaaaaaaaa aaaaaaaaaa ",
        },
      };
      return boardTestData;
    },

    addTestData() {
      let copy = this.list.slice();
      copy.push(this.getTestData());
      this.$emit("update:list", copy);
    },

    // 時間を何日前表示する
    formatTime(dateStr) {
      let date = dayjs(dateStr);
      date.tz("Asia/Tokyo");
      return date.fromNow();
    },

    // 数字を省略する 1000 -> 1k
    formatNum(num) {
      if (num >= 1000000) {
        let n = Math.round(num / 1000000);
        return n + "M";
      }
      if (num >= 1000) {
        let n = Math.round((num * 10) / 1000) / 10;
        return n + "k";
      }
      return num + "";
    },

    // 投票所のリストを取得
    async getList() {
      // console.log("get list");
      try {
        const res = await API.graphql({
          query: byCreatedAt,
          variables: { type: "b", sortDirection: "DESC" },
        });
        console.log("投票所一覧を取得 :", res);
        this.$emit("update:list", res.data.byCreatedAt.items);
      } catch (e) {
        console.log(e);
        this.isErrorPage = true;
      }
    },

    // boardのupdateをサブスク
    // public用
    subscribeUpdateRequestBoard() {
      // サインイン状況で切り替える
      this.subscriptionUpdate = API.graphql({
        query: onUpdateRequestBoardPublic,
      }).subscribe({
        next: (eventData) => {
          console.log("subscr req ", eventData);
          if (eventData.value.data.onUpdateRequestBoardPublic == null) {
            return;
          }
          let request = eventData.value.data.onUpdateRequestBoardPublic;
          // board id が同じなら update
          if (this.requestBoard.id === request.boardId) {
            Util.listUpdate(this.requestBoard.list.items, request);
          }
        },
        error: (e) => {
          console.log("サブスクエラー", e);
        },
      });
    },

    // create boardをサブスク
    // public 用
    subscribeCreateRequestBoardPublic() {
      this.subscriptionCreate = API.graphql({
        query: onCreateRequestBoardPublic,
      }).subscribe({
        next: (eventData) => {
          console.log("subscr req ", eventData);
          if (eventData.value.data.onCreateRequestBoardPublic == null) {
            return;
          }
          let board = eventData.value.data.onCreateRequestBoardPublic;
          // listにあればupdateなければunshift(追加)
          if (this.list.some((_board) => _board.id === board.id)) {
            Util.updateListItem(this.list, board);
          } else {
            let copy = this.list;
            copy.unshift(board);

            this.$emit("update:list", copy);
          }
        },
        error: (e) => {
          console.log("サブスクエラー", e);
        },
      });
    },

    // サブスク設定
    subscribe() {
      // 今の所createのみ
      this.subscribeCreateRequestBoardPublic();
    },

    unSubscribe() {
      if (this.subscriptionCreate != null)
        this.subscriptionCreate.unSubscribe();
    },
  },

  async created() {
    await this.getList();
    // this.addTestData();
  },

  beforeDestroy() {
    this.unSubscribe();
  },
};
</script>