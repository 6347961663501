











































import Vue from "vue";
import { API } from "aws-amplify";
import { updateRequestBoard } from "@/graphql/mutations";
import Util from "@/Util/Util";

export default Vue.extend({
  data() {
    return {
      // 投稿エラーフラグ
      isFormError: false,
      valid: true,
      // form
      form: {
        title: this.requestBoard.title,
        description: this.requestBoard.description,
        url: this.requestBoard.url,
      },
      // バリデーションルール
      titleFormRule: [
        (value: string | null) => !!value || "作成するにはタイトルが必要です",
        (value: string | null) =>
          value == null || !(value.length > 100) || "100文字以内にしてください",
      ],
      descriptionFormRule: [
        (value: string | null) =>
          value == null ||
          !(value.length > 1000) ||
          "1000文字以内にしてください",
      ],
      urlRule: [
        (v: string | null) =>
          v == null ||
          !v ||
          Util.isValidHttpUrl(v) ||
          "url形式の文字列のみ有効",
      ],
    };
  },
  props: {
    dialog: {
      type: Boolean,
    },
    requestBoard: {
      type: Object,
    },
  },
  methods: {
    // フォームをクリアする
    clearForm() {
      (this.$refs.form as any).reset();
      this.isFormError = false;
    },

    // 投稿
    async onClickSubmit() {
      await this.createNewBoard();
    },

    async updateRequestBoard() {
      // 作成
      const res: any = await API.graphql({
        query: updateRequestBoard,
        variables: {
          input: {
            id: this.requestBoard.id,
            title: this.form.title,
            description: !this.form.description ? null : this.form.description,
            url: !this.form.url ? null : this.form.url,
          },
        },
      });
      console.log("投票所データを更新! ", res);
      this.$emit("update:requestBoard", res.data.updateRequestBoard);
    },

    async createNewBoard() {
      // バリデーション起動とチェック
      if (!(this.$refs.form as any).validate()) {
        console.log("バリデーションエラー！");
        return;
      }
      try {
        await this.updateRequestBoard();
        this.clearForm();
        this.$emit("update:dialog", false);
      } catch (e) {
        console.log(e);
        this.isFormError = true;
      }
    },
  },
});
