<template>
  <v-form ref="form" v-model="isValid">
    <v-text-field
      counter
      v-model="userName"
      ref="userNameInput"
      :rules="rules"
      label="ユーザー名"
    >
    </v-text-field>
    <v-textarea
      counter
      v-model="bio"
      ref="bioInput"
      label="自己紹介"
      :rules="profileRules"
    >
    </v-textarea>
    <div class="d-flex justify-center">
      <v-btn id="button" class="mt-4" depressed color="primary" @click="onClick"
        >登録</v-btn
      >
    </div>
  </v-form>
</template>

<script>
import { Auth } from "@aws-amplify/auth";
import { API } from "@aws-amplify/api";
import { createUser } from "@/graphql/mutations";

export default {
  data() {
    return {
      isValid: true,
      userName: "",
      bio: "",

      rules: [
        // null と空文字チェック
        (value) => !!value || "項目を入力してください",
        // null ok 200文字以内
        (v) => v == null || v.length <= 50 || "最大50文字まで",
      ],

      profileRules: [
        // null ok 200文字以内
        (v) => v == null || v.length <= 200 || "最大200文字まで",
      ],
    };
  },

  methods: {
    // dbのプロフィールを作成
    async createUserData() {
      const user = this.$store.state.user;
      let res = await API.graphql({
        query: createUser,
        variables: {
          input: {
            id: user.username,
            preferred_username: this.userName,
            profile: this.bio,
          },
        },
      });
      console.log("graphql ユーザープロフィールを更新", res);
    },

    // routerのフラグに使うためCognitoUserPoolの状態を更新
    async updateCoginitoUserData() {
      const user = this.$store.state.user;
      const attributes = {
        preferred_username: this.userName,
        profile: this.bio,
        ...user.attributes,
      };

      let res = await Auth.updateUserAttributes(user, attributes);
      console.log("CognitoUserPoolの状態を変更: ", res);
    },

    // 決定ボタンをクリック
    async onClick() {
      if (!this.$refs["form"].validate()) {
        // バリデーションエラー何もしない
        return;
      } else {
        try {
          await this.createUserData();
          await this.updateCoginitoUserData();
          this.$router.push("/");
        } catch (e) {
          // 更新できなかった場合何もしない
          console.error(e);
          return;
        }
      }
    },
  },
};
</script>